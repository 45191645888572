<template>
  <section>
    <PollLikeDislikeFilter />
    <Preloader v-if="callingAPI" />
    <PollLikeDislikeTable
      :polls="polls"
      :totalCount="totalCount"
      :current-page="page"
      :on-page-change="setPageAndGetRecords"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '../../components/preloader/Preloader'
import PollLikeDislikeFilter from '../../components/filter/FilterLikeDislike'
import PollLikeDislikeTable from '@/components/table/PollLikeDislikeTable'

export default {
  name: 'LikeDislikeListView',
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    polls () {
      return this.$store.getters['poll/list']
    },
    totalCount () {
      return this.$store.getters['poll/totalCount']
    },
    page () {
      return this.$store.getters['poll/page']
    }
  },
  components: {
    PollLikeDislikeTable,
    Preloader,
    PollLikeDislikeFilter
  },
  methods: {
    getPolls () {
      this.$store.dispatch('poll/fetchLikeDislike')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('poll/setPage', page)
      this.getPolls()
    }
  },
  mounted () {
    this.getPolls()
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>
